import { Component } from 'react';

import Header from './../../PagesComponents/Headers/Header';
import Footer from './../../PagesComponents/Footer/Footer';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import {
  Link, withRouter
} from "react-router-dom";
import Banner from './../../PagesComponents/GeneralComponents/Banner/Banner';
import ProductGallery from './../../PagesComponents/SectionProduct/ProductGallery/ProductGallery';
import AccordionDetailsProduct from './../../PagesComponents/SectionProduct/AccordionDetailsProduct/AccordionDetailsProduct';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import Instagram from './../../PagesComponents/GeneralComponents/Instagram/Instagram';

import './Product.scss';

class Product extends Component {

  state = {
    idProduct: null,
    productInfo: {},
    category: {},
    imgsList: [],
    dataSheet: [],
    dwgList: [],
    accessories: [],
    textileCategories: [],
    textiles: [],
    certifications: []
  };

  componentDidMount() {
    this.setState({
      idProduct: this.props.match.params.idProduct
    });
    this.getCategoryInfoAndProducts(this.props.match.params.idProduct);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.getCategoryInfoAndProducts(this.props.match.params.idProduct);
    }
  }

  getCategoryInfoAndProducts(idProduct) {
    fetch(`${global.config.URL.SERVER}/product/${idProduct}`)
      .then(response => response.json())
      .then(data => {
        console.log(data);
        this.setState({
          productInfo: data.product,
          category: data.category,
          imgsList: data.imgs,
          dataSheet: data.dataSheet,
          dwgList: data.dwg,
          accessories: data.accessories,
          textileCategories: data.textileCategories,
          textiles: data.textiles,
          certifications: data.certifications
        })
      } );
  }

  render() {
    return (
      <div className="product-component">
        <Header/>
        <Banner inner={
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" to="/">
              Inicio
            </Link>
            <Typography color="inherit">
              Productos
            </Typography>
            <Link color="inherit" to={`/categoria/${this.state.category.url}`}>
              {this.state.category.shortName}
            </Link>
            <Typography color="textPrimary">{this.state.productInfo.code}</Typography>
          </Breadcrumbs>
          }
          name = {this.state.productInfo.name}
          bannerImage = {this.state.category.imgBanner}
        />

        <Container maxWidth="lg" className="mb-10">
          <div id="container">
            <div id="sidebar">
              {this.state.imgsList.length > 0 &&
                <ProductGallery
                  imgMain={this.state.imgsList}
                />
              }
            </div>
            <div id="content">
              <div className="code-main">
                {this.state.productInfo.shortName}
              </div>
              <div>
                {this.state.productInfo.shortCode}
              </div>
              <div className="mb-3">
                <span className="mr-3">
                  Categoría:
                </span>
                <span>
                  <Link color="inherit" to={`/categoria/${this.state.category.url}`}>
                    {this.state.category.shortName}
                  </Link>
                </span>
              </div>
              <AccordionDetailsProduct
                description={this.state.productInfo.description}
                descontinuado={this.state.productInfo.descontinuado}
                colorMain={this.state.productInfo.colorMain}
                dataSheet={this.state.dataSheet}
                dwgList={this.state.dwgList}
                accessories={this.state.accessories}
                textileCategories={this.state.textileCategories}
                textiles={this.state.textiles}
                certifications={this.state.certifications}
              />
            </div> 
          </div>
        </Container>

        <Instagram/>
        <Footer/>
      </div>
    );
  }
}

export default withRouter(Product);