// React
import React from "react";
import { Carousel } from "react-bootstrap";


// CSS
import "./Carousell.css";
import "bootstrap/dist/css/bootstrap.min.css";

const Carousell = () => {
  return (
    <div className="container-md">
      <Carousel nextLabel={""} prevLabel={""}>
        {/* cambiar  */}
      <Carousel.Item>
          <a href="https://www.youtube.com/watch?v=Jy95IAghLHc&t=7s" target="_blank">
          <img
            className="d-block w-100"
            src="/img/homeBanners/banner0.webp"
            alt="Banner home"
          />
          </a>
        </Carousel.Item>

      <Carousel.Item>
          <a href="/#newProducts">
            <img
              className="d-block w-100"
              src="/img/homeBanners/banner1.webp"
              alt="Banner home"
            />
          </a>
        </Carousel.Item>
        <Carousel.Item>
          <a href="https://requiez.com/blog/NOM-037">
            <img
              className="d-block w-100"
              src="/img/homeBanners/banner2.webp"
              alt="Banner home"
            />
          </a>
        </Carousel.Item>

        <Carousel.Item>
          <a href="https://requiez.com/categoria/linea-ejecutiva">
            <img
              className="d-block w-100"
              src="/img/homeBanners/banner3.webp"
              alt="Banner home"
            />
          </a>
        </Carousel.Item>

        <Carousel.Item>
          <a href="https://requiez.com/producto/ZOE-KIDS-PIES">
            <img
              className="d-block w-100"
              src="/img/homeBanners/banner4.webp"
              alt="Banner home"
            />
          </a>
        </Carousel.Item>

       
      </Carousel>
    </div>
  );
};

export default Carousell;
