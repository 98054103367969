import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';

import Textiles from './../Textiles/Textiles';

import './AccordionDetailsProduct.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const backgroundColorDefault = 'rgb(255 255 255)';
// const backgroundColorDefault = 'rgb(29 38 71)';

export default function AccordionDetailsProduct(props) {
  const classes = useStyles();
  return (
    <div className="accordionDetailsProduct">
      <Accordion defaultExpanded className="accordion" style={{
        backgroundColor: (props.colorMain) ? props.colorMain : backgroundColorDefault
      }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Descripción
          {
            props.descontinuado === 1 && (
              <>
                <p style={{ color: "red", fontWeight: "bold" }}>
                ---PRODUCTO DESCONTINUADO---
                </p>
               
              </>
            )
          }
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {props.description}
          </Typography>
        </AccordionDetails>
      </Accordion>
      {props.dataSheet && props.dataSheet.length > 0 &&
        <Accordion className="accordion" style={{
          backgroundColor: (props.colorMain) ? props.colorMain : backgroundColorDefault
        }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>Ficha técnica</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="element-list">
              {props.dataSheet.map((value, index) => {
                return <a href={`/img/products/${value.img}`} key={index} target="_blank" rel="noreferrer">
                  <Button className="download-file" variant="contained" color="primary">
                    {value.name}
                  </Button>
                </a>
              })}
            </div>
          </AccordionDetails>
        </Accordion>
      }
      {props.accessories && props.accessories.length > 0 &&
        <Accordion className="accordion" style={{
          backgroundColor: (props.colorMain) ? props.colorMain : backgroundColorDefault
        }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>Accesorios</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="element-list">
              {props.accessories.map((value, index) => {
                return <div className="container-img text-center" key={index}>
                  <img src={`/img/${value.img}`} alt={value.name} />
                  {value.name}
                </div>
              })}
            </div>
          </AccordionDetails>
        </Accordion>
      }
      {/* Acordion de textiles o materialidad */}
      {props.textileCategories && props.textileCategories.length > 0 &&
        <Accordion className="accordion" style={{
          backgroundColor: (props.colorMain) ? props.colorMain : backgroundColorDefault
        }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            {/* Se busca por id de textile y si ees mayor al numero 66 es materialidad */}
            <Typography className={classes.heading}>
              {props.textiles.some(textile => textile.id > 66) ? 'Materialidad' : 'Tapices'}
            </Typography>

          </AccordionSummary>
          <AccordionDetails>
            <div className="element-list">
              {props.textileCategories.map((value, index) => {
                const textiles = props.textiles.filter((obj) => (obj.idTextileCategories === value.id));
                return <div key={index}>
                  <Textiles
                    textileInfo={value}
                    textiles={textiles} />
                </div>
              })}
            </div>
          </AccordionDetails>
        </Accordion>
      }

      {props.dwgList && props.dwgList.length > 0 &&
        <Accordion className="accordion" style={{
          backgroundColor: (props.colorMain) ? props.colorMain : backgroundColorDefault
        }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>Archivos CAD</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="element-list">
              {props.dwgList.map((value, index) => {
                return <a href={`/img/products/${value.img}`} key={index} target="_blank" rel="noreferrer" download>
                  <Button className="download-file" variant="contained" color="primary">
                    {value.name}
                  </Button>
                </a>
              })}
            </div>
          </AccordionDetails>
        </Accordion>
      }
      {props.certifications && props.certifications.length > 0 &&
        <Accordion className="accordion" style={{
          backgroundColor: (props.colorMain) ? props.colorMain : backgroundColorDefault
        }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>Certificaciones</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="element-list">
              {props.certifications.map((value, index) => {
                return <div className="container-img-certifications" key={index}>
                  <img src={`/img/${value.img}`} alt={value.name} />
                </div>
              })}
            </div>
          </AccordionDetails>
        </Accordion>
      }
    </div>
  );
}