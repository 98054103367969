import React from 'react';

import Container from '@material-ui/core/Container';
import {
  Link
} from "react-router-dom";
import getThisYear from './getYear';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';

import './Footer.scss';

export default function Footer() {
  return (
    <div className="section-footer">
      <Container maxWidth="lg">
        <div className="container-columns">
          <div>
            <a href="/">
              <img className="img-logo" src="/img/logo-abajo.png" alt="Requiez logo"/>
            </a>
            <div>
              Visita nuestras redes sociales
            </div>
            <div className="mt-5">
              <div className="footer-social">
                <a target="_blank" href="https://www.instagram.com/requiez.sillas/" rel="noreferrer">
                  <InstagramIcon/>
                </a>
                <a target="_blank" href="https://www.facebook.com/Requiez.Oficina/" rel="noreferrer">
                  <FacebookIcon/>
                </a>
              </div>
            </div>
          </div>
          <div>
            <h4>
              Enlaces rápidos
            </h4>
            <div>
              <Link to="/alianzas">
                Alianzas
              </Link>
            </div>
            <div>
              <Link to="/nosotros">
                Nosotros
              </Link>
            </div>
            <div>
              <a href="/files/AvisoPrivacidad.pdf" target="_blank">Aviso de privacidad</a>
            </div>
            <div className="tienda-gruporequiez" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <a href="https://tienda.gruporequiez.com/" rel='noreferrer' target='_blank'>
              <button style={{ 
                  marginTop: '20px',
                  height: '30px',
                  width: '275px',
                  border: 'none',
                  cursor: 'pointer',
                  borderRadius: '15px',
                  fontWeight: 'bold',
                  color: 'black'
                }}>
                Visita nuestra tienda en línea
              </button>
            </a>
            <span style={{ height: '100%', display: 'grid', placeItems: 'center' }}>
              <img style={{ marginTop: '20px', height: '25px', marginLeft: '-40px'}} src="/img/carrito.png" alt="Grupo Requiez - Tienda en Linea" />
            </span>
          </div> 
          </div>
          <div>
            <h4>
              Contáctanos
            </h4>
            <div>
              <a href="https://goo.gl/maps/CRWiTjopBzHkfGhU7" target="_blank" rel="noreferrer">
                Avenida Paseo Punto Sur No. 312 <br/>
                Nivel 1, Interior V2 <br/>
                Corporativo Punto Sur <br/>
                Tlajomulco de Zúñiga, Jalisco, México
              </a>
            </div>
            <div>
              <a href="mailto:hola@gruporequiez.com">hola@gruporequiez.com</a>
            </div>
            <div>
              <a href="tel:+523338337788">+52 (33) 3833 7788</a>
            </div>
            <div>
              <a href="tel:+5591317237">+52 (55) 1317 7237</a>
            </div>
          </div>
          <div>
            <h4>
              Acerca de nosotros
            </h4>
            <div>
              <a href="/files/CodigoEticaGrupoRequiez.pdf" target="_blank">Código de ética</a>
            </div>
            <div>
              <Link to="contacto">
                Contacto
              </Link>
            </div>
          </div>
        </div>
        <div className="copyright mt-12 mb-0">
          Copyright ©{getThisYear()} All rights reserved | Reqüiez
        </div>
      </Container>
    </div>
  );
}